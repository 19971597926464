/*!
 * Bootstrap JS
 */
import 'bootstrap';

/*!
 * Bootstrap CSS and custom styles
 */
import '../scss/main.scss';

/*!
 * IE10 viewport hack for Surface/desktop Windows 8 bug
 */
import './ie10-viewport-bug-workaround.js';

require('jquery-circle-progress');
//require('datatables.net');
//require('datatables.net-bs4');

//import Highcharts from 'highcharts';

//regex to check if email valid
const email_regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const username_regex = /^[^\s]+$/;

const requiredPasswordLength = 6;

let storageSupported = false;
let lights = false;

if (typeof(Storage) !== "undefined") {
    // Code for localStorage/sessionStorage.
    storageSupported = true;
}

if (storageSupported && localStorage.getItem("lights") === "on") {
    document.body.className += ' lights-on';
    lights = true;
}

$(document).ready(function () {


    //highlight nav element
    const currentPageUrl = location.href;
    $('.navbar-nav li a').each(function(){
        $(this).toggleClass('active', currentPageUrl === this.href);
    });
});

$('#light-switch').click(function () {
    if (lights) {
        $('body').removeClass("lights-on");
        lights = false;
        localStorage.setItem("lights", "off");
    } else {
        $('body').addClass("lights-on");
        lights = true;
        localStorage.setItem("lights", "on");
    }
});

const circleProgressElements = $('.circle-progress');
circleProgressElements.circleProgress({
    startAngle: -Math.PI * 0.5,
    size: 100,
    fill: '#569E3D',
    lineCap: 'round'
});

circleProgressElements.filter(".percentage").circleProgress({}).on('circle-animation-progress', function (event, progress, stepValue) {
    var percent = 100 * stepValue;
    $(this).find('strong').html(parseInt(percent) + '<i>%</i>');
});

circleProgressElements.filter(":not(.percentage)").circleProgress({}).on('circle-animation-progress', function (event, progress, stepValue) {
    var val = +(stepValue * $(this).data("max")).toFixed(2);
    $(this).find('strong').html(val);
});

$('#nav-login-form').submit(function (evt) {
    if (!$("#nav-login-form > [name='username']").val() && !$("#nav-login-form > [name='password']").val()) {
        evt.preventDefault();
        location.href = "loginpage";
    }
});

$(function(){
    if ($(location).attr('pathname').startsWith("/loginpage") && $(location).attr('search').startsWith("?register")) {
        $('#collapseTwo').collapse('show');
    }

    $('#notification-form').submit(function(event) {
        let postData = {
            'notifications_enabled': $(this).find("input[name='notifications-enabled']").is(':checked')
        };
        $.ajax ({
            type: "POST",
            url: "setnotifications.php",
            data: postData,
            encode: true,
            cache: false,
            success: function() {
                $("#set-notification-success").fadeIn().delay(5000).fadeOut();
            }
        });
        event.preventDefault();
    });

    $('form.setwish-form').submit(function(event) {
        var postData = {
            'text': $(this).find("textarea[name='wishtext']").val()
        };
        $.ajax ({
            type: "POST",
            url: "setwish.php",
            data: postData,
            encode: true,
            cache: false,
            success: function() {
                $("#set-wish-success").fadeIn().delay(5000).fadeOut();
            }
        });
        event.preventDefault();
    });

    const signUpForm = $('#signup-form');
    signUpForm.find('input#username-signup').on("input change paste", function() {
        /*if (!username_regex.test($(this).val())) {
            $(this).get(0).setCustomValidity("Benutzername darf keine Leerzeichen enthalten");
            $(this).next().html("Benutzername darf keine Leerzeichen enthalten");
        } else*/
        if ($(this).val().length < 3) {
            $(this).get(0).setCustomValidity("Benutzername muss mindestens 3 Zeichen lang sein");
            $(this).next().html("Benutzername muss mindestens 3 Zeichen lang sein");
        } else if ($(this).val().length > 32) {
            $(this).get(0).setCustomValidity("Benutzername darf höchstens 32 Zeichen lang sein");
            $(this).next().html("Benutzername darf höchstens 32 Zeichen lang sein");
        } else {
            $(this).get(0).setCustomValidity("");
            $(this).next().html();
            const tmp = $(this);
            $.ajax({
                type: "POST",
                url: 'checkregistration.php',
                data: { username: tmp.val() },
                cache: false,
                success: function(data) {
                    const result = JSON.parse(data);
                    if (result["valid"]) {
                        tmp.get(0).setCustomValidity("");
                        tmp.next().html();
                    } else {
                        tmp.get(0).setCustomValidity("Bitte wähle einen anderen Benutzernamen");
                        tmp.next().html("Bitte wähle einen anderen Benutzernamen");
                    }
                },
                error: function(data) {
                    tmp.get(0).setCustomValidity("");
                    tmp.next().html();
                }
            });
        }
        setTimeout($(this).parent().addClass("was-validated"), 1000);
    });

    signUpForm.find('input#password-signup').on("input change paste", function() {
        if ($(this).val().length < requiredPasswordLength) {
            $(this).get(0).setCustomValidity("Passwort muss mindestens " + requiredPasswordLength + " Zeichen lang sein");
            $(this).next().html("Passwort muss mindestens " + requiredPasswordLength + " Zeichen lang sein");
        } else {
            $(this).get(0).setCustomValidity("");
            $(this).next().html();
            const confirm = signUpForm.find('input#password-confirm-signup');
            if ($(this).val() === confirm.val()) {
                confirm.setCustomValidity("");
                confirm.next().html();
            }
        }
        setTimeout($(this).parent().addClass("was-validated"), 750);
    });

    signUpForm.find('input#password-confirm-signup').on("input change paste", function() {
        if ($(this).val() === signUpForm.find('input#password-signup').val()) {
            $(this).get(0).setCustomValidity("");
            $(this).next().html();
        } else {
            $(this).get(0).setCustomValidity("Passwort-Bestätigung stimmt nicht überein");
            $(this).next().html("Passwort-Bestätigung stimmt nicht überein");
        }
        setTimeout($(this).parent().addClass("was-validated"), 750);
    });

    signUpForm.find('input#email-signup').on("input change paste", function() {
        if (!email_regex.test($(this).val())) {
            $(this).get(0).setCustomValidity("Keine gültige E-Mail-Adresse");
            $(this).next().html("Keine gültige E-Mail-Adresse");
        } else {
            $(this).get(0).setCustomValidity("");
            $(this).next().html();
            const tmp = $(this);
            $.ajax({
                type: "POST",
                url: 'checkregistration.php',
                data: {email: tmp.val()},
                cache: false,
                success: function (data) {
                    const result = JSON.parse(data);
                    if (result["valid"]) {
                        tmp.get(0).setCustomValidity("");
                        tmp.next().html();
                    } else {
                        tmp.get(0).setCustomValidity("Bitte wähle eine andere E-Mail-Adresse");
                        tmp.next().html("Bitte wähle eine andere E-Mail-Adresse");
                    }
                },
                error: function (data) {
                    tmp.get(0).setCustomValidity("");
                    tmp.next().html();
                }
            });
        }
        setTimeout($(this).parent().addClass("was-validated"), 750);
    });

    const changePWForm = $('#change-password-form');
    changePWForm.find('input#new-password').on("input change paste", function() {
        if ($(this).val().length < requiredPasswordLength) {
            $(this).get(0).setCustomValidity("Passwort muss mindestens " + requiredPasswordLength + " Zeichen lang sein");
            $(this).next().html("Passwort muss mindestens " + requiredPasswordLength + " Zeichen lang sein");
        } else {
            $(this).get(0).setCustomValidity("");
            $(this).next().html();
            const confirm = changePWForm.find('input#new-password-conform');
            if ($(this).val() === confirm.val()) {
                confirm.setCustomValidity("");
                confirm.next().html();
            }
        }
        setTimeout($(this).parent().addClass("was-validated"), 750);
    });

    changePWForm.find('input#new-password-confirm').on("input change paste", function() {
        if ($(this).val() === changePWForm.find('input#new-password').val()) {
            $(this).get(0).setCustomValidity("");
            $(this).next().html();
        } else {
            $(this).get(0).setCustomValidity("Passwort-Bestätigung stimmt nicht überein");
            $(this).next().html("Passwort-Bestätigung stimmt nicht überein");
        }
        setTimeout($(this).parent().addClass("was-validated"), 750);
    });
});

window.addEventListener('load', function() {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function(form) {
        form.addEventListener('submit', function(event) {
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }
            form.classList.add('was-validated');
        }, false);
    });
}, false);


/**
 * function to convert minutes (integer value) into a string containing hours and minutes
 * @param minutes
 * @returns {string}
 */
function timeString(minutes) {
    minutes = parseInt(minutes);
    const hours = Math.floor(minutes / 60);
    minutes = minutes - hours * 60;
    var str = minutes + " min";
    if (hours > 0) {
        str = hours + " h ";
        if (minutes > 0) {
            str += minutes + " min";
        }
    }
    return str;
}

/**
 * Function to retrieve a get parameter from the current document (using location.href)
 *
 * @param parameter {String} Key of the get parameter to retrieve
 */
function _get(parameter) {
    const reg = new RegExp( '[?&]' + parameter + '=([^&#]*)', 'i' );
    var string = reg.exec(window.location.href);
    return string ? string[1] : undefined;
}
